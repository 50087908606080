import React from 'react';
import columns from './purchase_order_columns';
import { ViewTable, Dialog, Layout, Filter, Button } from '@/components';
import apis from '@/api/api';
import { routerMap } from '@/router/agent_router';
import { PURCHASE_ORDER_STATUS } from '@/enum-data/enum';
import WebApi from '@/web/api';
import { LibISupplier } from 'lib/interface';
import { LibAuthCode } from 'lib/Auth';
import LibUtils from 'lib/utils';
import LibEnum from 'lib/enum';

export default class PurchaseOrderPage extends React.PureComponent<RouterPropsType> {

  private table = React.createRef<ViewTable>()
  private params = {};

  state = {
    visible: false,
    loading: false,
  }

  private findPurchaseAll = () => {
    this.table.current?.reset(this.params);
  }

  public refresh = () => {
    this.table.current?.refresh();
  }

  render() {
    const { history } = this.props;

    return (
      <Layout.Page>
        <Layout.Toolbar title="进货单">
          <Button type="primary" authCode={LibAuthCode.purchaseOrder.create} onClick={() => this.props.history.push(routerMap.purchase_order_create.path)}>新增进货单</Button>
          <Button.Refresh onClick={() => {
            this.table.current?.refresh();
          }}/>
        </Layout.Toolbar>
        {/* 搜索条件 */}
        <Filter
          fields={[
            {
              type: 'select',
              name: '供应商',
              width: 200,
              field: 'supplierId',
              options: async () => {
                const data: LibISupplier[] = await WebApi.supplier_list() as any;
                return data.map(item => {
                  return {
                    name: item.supplierName,
                    value: item.supplierId
                  };
                })
              },
            },
            {
              type: 'select',
              field: 'orderStatus',
              name: '订单状态',
              options: LibUtils.enumToOptions(LibEnum.PURCHASE_ORDER_STATUS),
            },
            {
              type: 'select',
              field: 'paymentStatus',
              name: '付款状态',
              options: LibUtils.enumToOptions(LibEnum.PAYMENT_STATUS_2),
            },
            {
              type: 'input',
              field: 'keyword',
              name: '搜索',
              placeholder: '订单号/供应商',
              options: LibUtils.enumToOptions(LibEnum.PAYMENT_STATUS_2),
            }
          ]}
          onChange={values => {
            this.params = values;
            this.table.current?.reset();
          }}
        />

        {/* table 数据 */}
        <ViewTable
          flex1
          ref={this.table}
          fetch={data => {
            return WebApi.purchase_order_paging({...data, ...this.params});
          }}
          actionButtons={[
            {
              type: 'invalid',
              authCode: LibAuthCode.purchaseOrder.update,
              disabled: row => row.orderStatus !== PURCHASE_ORDER_STATUS.WAIT_AUDIT.value,
              onClick: row => {
                if (row.orderStatus !== '11') {
                  Dialog.warning({
                    title: '不可作废',
                    content: '只有待审核的订单才可以作废！'
                  });
                  return;
                }
                this.clickInvalid(row);
              }
            },
            {
              type: 'delete',
              authCode: LibAuthCode.purchaseOrder.delete,
              disabled: row => row.orderStatus !== PURCHASE_ORDER_STATUS.INVALID.value,
              onClick: row => {
                if (row.orderStatus !== '99') {
                  Dialog.warning({
                    title: '不可删除',
                    content: '只有作废的订单才可以删除！'
                  });
                  return;
                }
                this.clickDelete(row);
              },
            }
          ]}
          columns={columns as any}
          rowKey={row => row.purchaseId}/>
      </Layout.Page>
    )
  }


  // 作废订单
  clickInvalid = record => {
    Dialog.confirm({
      content: '确认作废订单 “' + record.purchaseId + '” 吗？',
      onOk: async() => {
        WebApi.purchase_order_cancel({
          purchaseOrderId: record.purchaseId,
        })
        .then(() => {
          showSuccess.save();
          this.findPurchaseAll();
        })
      },
    });
  }

  // 删除订单
  clickDelete = record => {
    Dialog.confirm({
      content: `确认删除订单 “${record.purchaseId}” 吗？`,
      onOk: async () => {
        WebApi.purchase_order_delete({
          purchaseOrderId: record.purchaseId,
        })
        .then(() => {
          showSuccess('订单已删除');
          this.findPurchaseAll();
        })
      }
    });
  }
}